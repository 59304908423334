
import Vue, { PropType } from 'vue'
import SiteBreadcrumbs, { Breadcrumb } from '~/components/site/Breadcrumbs.vue'
import SitePageHeader from '~/components/site/PageHeader.vue'
import SiteLayoutWithSideContent from '~/components/site/layout/WithSideContent.vue'

export default Vue.extend({
  name: 'ArticlePageTitle',
  components: {
    SiteLayoutWithSideContent,
    SitePageHeader,
    SiteBreadcrumbs,
  },
  props: {
    breadcrumb: {
      type: Object as PropType<Breadcrumb>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
})
