
import Vue from 'vue'
import { images, metadata } from '~/assets/ts/utils/metadata'
import { ArticleApi } from '~/apiclient/apiarticles'
import ArticlePage from '~/components/article/Page.vue'
import SiteLayoutWithPromo from '~/components/site/layout/WithPromo.vue'
import { ArticleDisplay } from '~/assets/ts/enums'
import { webpUrl } from '~/assets/ts/utils/image'
import ArticlePageTitle from '~/components/article/PageTitle.vue'
import { StructuredDataArticle } from '~/assets/ts/utils/structured-data'

export default Vue.extend({
  components: {
    ArticlePageTitle,
    SiteLayoutWithPromo,
    ArticlePage,
  },
  layout: 'site',
  middleware: ['fetch/specialArticle'],
  head(this: any) {
    return metadata(this, {
      title: `${this.title} | SermonAudio News`,
      description: this.description,
      images: this.imageUrl ? images(this.imageUrl, true) : undefined,
      data: StructuredDataArticle(this.article, this.articleDisplay),
    })
  },
  computed: {
    imageUrl(): string | undefined {
      if (!this.article?.bannerURL) return undefined
      return webpUrl(this.article.bannerURL, { width: 1920 })
    },
    description(): string | undefined {
      return this.article.preview ?? undefined
    },
    articleDisplay(): ArticleDisplay {
      return ArticleDisplay.News
    },
    title(): string {
      return this.article.title ?? ''
    },
    article(): ArticleApi {
      return this.$store.getters['articles/article']
    },
  },
})
