
import Vue, { PropType } from 'vue'
import { ArticleApi } from '~/apiclient/apiarticles'
import ArticlePageHeader from '~/components/article/PageHeader.vue'
import SaImage from '~/components/_general/SaImage.vue'
import { webpUrl } from '~/assets/ts/utils/image'
import SiteBanner from '~/components/site/Banner.vue'
import { Broadcaster } from '~/models/broadcaster'
import MarkdownAdvancedElement from '~/components/markdown/AdvancedElement.vue'
import { ArticleDisplay, SpeakerDisplay } from '~/assets/ts/enums'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { Speaker } from '~/models/speaker'
import SpeakerElement from '~/components/_general/SpeakerElement.vue'
import SitePageHeader from '~/components/site/PageHeader.vue'
import SiteManageButtons from '~/components/site/ManageButtons.vue'
import { GetArticleUrl } from '~/assets/ts/utils/urls'
import BlbTagger from '~/components/_general/BlbTagger.vue'

export default Vue.extend({
  name: 'ArticlePage',
  components: {
    BlbTagger,
    SiteManageButtons,
    SitePageHeader,
    SpeakerElement,
    HorizontalRule,
    MarkdownAdvancedElement,
    SiteBanner,
    SaImage,
    ArticlePageHeader,
  },
  props: {
    article: {
      type: Object as PropType<ArticleApi>,
      required: true,
    },
    display: {
      type: Number as PropType<ArticleDisplay>,
      default: ArticleDisplay.Standard,
    },
    print: {
      type: Boolean,
    },
  },
  computed: {
    imageUrl(): string {
      if (!this.article.bannerURL) return ''
      return webpUrl(this.article.bannerURL)
    },
    editUrl(): string {
      return GetArticleUrl(ArticleDisplay.DjangoDashboard, this.article)
    },
    broadcaster(): Broadcaster {
      return new Broadcaster(this.article.broadcaster)
    },
    authorDisplay(): SpeakerDisplay {
      return SpeakerDisplay.Expanded
    },
    // TODO: At some point we'll want next/previous articles back. Enable this when we do.
    // next(): DisplayArticleApi | undefined {
    //   return this.article.nextArticle || undefined
    // },
    // prev(): DisplayArticleApi | undefined {
    //   return this.article.previousArticle || undefined
    // },
    author(): Speaker | undefined {
      return this.article.speaker
        ? new Speaker(this.article.speaker)
        : undefined
    },
  },
})
