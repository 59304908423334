
import Vue, { PropType } from 'vue'
import { ArticleApi } from '~/apiclient/apiarticles'
import BroadcasterElement from '~/components/_general/BroadcasterElement.vue'
import {
  ArticleDisplay,
  BroadcasterDisplay,
  DateFormat,
  SpeakerDisplay,
} from '~/assets/ts/enums'
import { Broadcaster } from '~/models/broadcaster'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import { apiDateToJsDate, localizeDateTime } from '~/assets/ts/utils/date'
import { Speaker } from '~/models/speaker'
import SpeakerElement from '~/components/_general/SpeakerElement.vue'
import SaLink from '~/components/_general/SaLink.vue'
import { GetArticleUrl, siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import ShareButton from '~/components/_general/ShareButton.vue'
import SiteButton from '~/components/site/Button.vue'
import { openPopoutWindow } from '~/assets/ts/utils/misc'

export default Vue.extend({
  name: 'ArticlePageHeader',
  components: {
    SiteButton,
    ShareButton,
    SaLink,
    SpeakerElement,
    InlineIcon,
    BroadcasterElement,
  },
  props: {
    article: {
      type: Object as PropType<ArticleApi>,
      required: true,
    },
    display: {
      type: Number as PropType<ArticleDisplay>,
      default: ArticleDisplay.Standard,
    },
    print: {
      type: Boolean,
    },
  },
  computed: {
    SpeakerDisplay() {
      return SpeakerDisplay
    },
    broadcasterDisplay(): BroadcasterDisplay {
      return BroadcasterDisplay.Info
    },
    news(): boolean {
      return this.display === ArticleDisplay.News
    },
    releaseNote(): boolean {
      return this.display === ArticleDisplay.ReleaseNotes
    },
    sa(): boolean {
      return this.news || this.releaseNote
    },
    showCategory(): boolean {
      return !this.sa && !!this.category
    },
    broadcaster(): Broadcaster {
      return new Broadcaster(this.article.broadcaster)
    },
    category(): string {
      return this.article.categoryDescription ?? ''
    },
    printUrl(): string {
      return `${GetArticleUrl(ArticleDisplay.Standard, this.article)}/print`
    },
    categoryLink(): string {
      if (!this.article.categoryID) return ''
      const url = siteBroadcasterUrl(this.broadcaster, 'articles')
      return `${url}?category=${this.article.categoryID}`
    },
    author(): Speaker | undefined {
      return this.article.speaker
        ? new Speaker(this.article.speaker)
        : undefined
    },
    date(): string {
      const date = apiDateToJsDate(this.article.articleDate) as Date
      return localizeDateTime(date, DateFormat.Date)
    },
  },
  methods: { openPopoutWindow },
})
