
import Vue from 'vue'
import {
  MarkdownClasses,
  MarkdownInstance,
  MarkdownProps,
  MarkdownStyles,
} from '~/assets/ts/markdown/renderer'
import { SaMarkdown } from '~/assets/ts/markdown/sermonaudio'
import MarkdownPlayer from '~/components/markdown/Player.vue'
import { MaxMarkdownPlayers } from '~/assets/ts/markdown/player'
import { ElementStyle } from '~/assets/ts/types/misc'

interface PlayerNode {
  type: 'player'
  params: SaMarkdown
}

interface HtmlNode {
  type: 'html'
  html: string
}

type MarkdownNode = HtmlNode | PlayerNode

export default Vue.extend({
  name: 'MarkdownAdvancedElement',
  components: { MarkdownPlayer },
  props: {
    ...MarkdownProps,
    element: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    text(): string {
      return this.body.toString()
    },
    nodes(): MarkdownNode[] {
      const marked = MarkdownInstance(this.$props)
      if (!this.players) {
        return [this.toHtml(marked.parse(this.text) as string)]
      }
      const nodes = marked.lexer(this.text).map((t) => {
        const player = (t as any).params as SaMarkdown | undefined
        return player ? this.toPlayer(player) : this.toHtml(marked.parser([t]))
      })
      let playerCount = 0
      const finalNodes = [] as MarkdownNode[]
      nodes.forEach((n) => {
        if (n.type === 'player') {
          if (playerCount < MaxMarkdownPlayers) {
            finalNodes.push(n)
            playerCount += 1
          }
        } else {
          const currentNode = finalNodes[finalNodes.length - 1]
          if (currentNode?.type === 'html') {
            currentNode.html += n.html
          } else {
            finalNodes.push(n)
          }
        }
      })
      return finalNodes
    },
    classes(): string {
      return MarkdownClasses(this.$props)
    },
    styles(): ElementStyle {
      return MarkdownStyles(this.$props)
    },
  },
  methods: {
    toHtml(html: string): HtmlNode {
      return { type: 'html', html }
    },
    toPlayer(params: SaMarkdown): PlayerNode {
      return { type: 'player', params }
    },
  },
})
