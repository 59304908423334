
import Vue, { PropType } from 'vue'

type BannerColors = 'yellow' | 'red'
type BannerSizes = 'small' | 'normal' | 'large'

export default Vue.extend({
  name: 'SiteBanner',
  props: {
    size: {
      type: String as PropType<BannerSizes>,
      default: 'normal',
    },
    color: {
      type: String as PropType<BannerColors>,
      default: 'yellow',
    },
  },
  computed: {
    colorClasses(): string {
      switch (this.color) {
        case 'red':
          return 'bg-red-light dark:bg-red'
        case 'yellow':
        default:
          return 'bg-yellow-light dark:bg-yellow text-black'
      }
    },
    sizeClasses(): string {
      switch (this.size) {
        case 'large':
          return 'px-8 pt-8 pb-10'
        case 'small':
          return 'px-3 py-2.5 text-sm'
        case 'normal':
        default:
          return 'px-6 py-5 text-sm'
      }
    },
  },
})
